import store from '@/store/index';
/**
 * pay
 * @param {'intrabank' | 'ach' | 'check' | 'domestic' | 'card'} paymentType
 * @param {*} paymentDetails
 * @param {*} updateContact
 */
export async function payToContact(
  paymentType,
  paymentDetails,
  updateContactDetails = null
) {
  if (updateContactDetails) {
    const contact = await store.dispatch(
      'contact/updateContact',
      updateContactDetails
    );
    store.commit('contact/updateSelectedContactData', contact);
  }

  let data = null;
  if (paymentType === 'intrabank') {
    data = await store.dispatch('pay/intrabankPay', paymentDetails);
  } else if (paymentType === 'ach') {
    data = await store.dispatch('pay/achPay', paymentDetails);
  } else if (paymentType === 'check') {
    data = await store.dispatch('pay/checkPay', paymentDetails);
  } else if (paymentType === 'domestic') {
    data = await store.dispatch('pay/domesticPay', paymentDetails);
  } else if (paymentType === 'card') {
    data = await store.dispatch('pay/sendCard', paymentDetails);
  }

  return data;
}
